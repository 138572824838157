import ThemisCascadedInput from "@/components/shared/cascaded-input"
import { ISSUE_TYPES } from "@/constants"
import { FIELD_TYPES } from "@/constants"
export default {
  name      : "IssueFilters",
  components: {
    ThemisCascadedInput
  },
  data() {
    return {
      selectedIssueStatuses             : new Array(),
      selectedIssueTypes                : new Array(),
      selectedIssueResolutions          : new Array(),
      selectedDomains                   : new Array(),
      selectedAssignees                 : new Array(),
      selectedIssueIds                  : new Array(),
      numberOfItemsInSelectionText      : 1,
      numberOfItemsInAdvancedFilters    : 2,
      selectedDates                     : new Array(),
      selectedDueDates                  : new Array(),
      showDateRangeMenu                 : false,
      showDueDateRangeMenu              : false,
      showAdvancedButton                : true,
      showClearFilters                  : false,
      selectedAdvancedFilters           : new Array(),
      formFields                        : {},
      issueFields                       : {},
      selectedLabels                    : new Array(),
      selectedChannels                  : new Array(),
      selectedReportStatuses            : new Array(),
      selectedIssueAnonymisationStatuses: new Array(),
      selectedReportSource              : new Array(),
      selectedReportLanguage            : new Array(),
      isClearingAllFilters              : false,
      fieldsMap                         : {}
    }
  },
  props: {
    pIssues                     : Array,
    pIssueIds                   : Array,
    pDomains                    : Array,
    pIssueStatuses              : Array,
    pIssueResolutions           : Array,
    pUsers                      : Array,
    pAdvancedFilters            : Array,
    pLabels                     : Array,
    pChannels                   : Array,
    pReportStatuses             : Array,
    pIssueAnonymisationStatuses : Array,
    pFields                     : Array,
    pOptionListItems            : Array,
    pUserIssuesFiltersPreference: Object,
    pReportSources              : Array,
    pLanguages                  : Array,
    pIssueFields                : Array,
    pIssueTypes                 : Array
  },
  computed: {
    /**
     * returns only the shortlisted form fields from advanced search drop down
     */
    selectedFormFields() {
      const selectedFormFields = []
      if (this.pFields) {
        for (const field of this.pFields) {
          let fieldValues               = []
          const filteredOptionListItems = this.pOptionListItems.filter(optionList =>
            optionList.optionListId === field.optionListId)
          const isCascaded              = !!filteredOptionListItems.find(optionListItem =>
            optionListItem.parentId)?.parentId
          if (this.selectedAdvancedFilters.indexOf(`reporter-intake-forms-${field.systemName}`) !== -1) {
            if (field.type === FIELD_TYPES.BOOLEAN.value) {
              fieldValues = [this.$t("1782"), this.$t("1781")]
            } else if (isCascaded) {
              fieldValues = this.cascadedOptionListItems.filter(optionListItem =>
                optionListItem.optionListId === field.optionListId)
            } else {
              fieldValues = this.pOptionListItems
                .filter(optionListItem => optionListItem.optionListId === field.optionListId)
                .map(optionListItem => optionListItem.name)
            }
            if (this.formFields[field.systemName]) {
              for (const value of this.formFields[field.systemName]) {
                const isFieldValueAlreadyPresent = filteredOptionListItems.find(optionList =>
                  optionList.name === value)
                if (!isFieldValueAlreadyPresent) {
                  if (isCascaded) {
                    fieldValues.push({ name: value, children: [] })
                  } else {
                    fieldValues.push(value)
                  }
                }
              }
            }
            selectedFormFields.push({
              id         : field.id,
              systemName : field.systemName,
              values     : fieldValues,
              tableColumn: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE,
              isCascaded
            })
          }
        }
      }
      return selectedFormFields
    },
    selectedIssueFields() {
      const selectedIssueFields = []
      if (this.pIssueFields) {
        for (const issueField of this.pIssueFields) {
          let fieldValues = []
          const field     = this.fieldsMap[issueField.fieldId]
          if (field) {
            const filteredOptionListItems = this.pOptionListItems.filter(optionList =>
              optionList.optionListId === field.optionListId)
            const isCascaded              = !!filteredOptionListItems.find(optionListItem =>
              optionListItem.parentId)?.parentId
            if (this.selectedAdvancedFilters.indexOf(`issue-fields-${field.systemName}`) !== -1) {
              if (field.type === FIELD_TYPES.BOOLEAN.value) {
                fieldValues = [this.$t("1782"), this.$t("1781")]
              } else if (isCascaded) {
                fieldValues = this.cascadedOptionListItems.filter(optionListItem =>
                  optionListItem.optionListId === field.optionListId)
              } else {
                fieldValues = this.pOptionListItems
                  .filter(optionListItem => optionListItem.optionListId === field.optionListId)
                  .map(optionListItem => optionListItem.name)
              }
              if (this.issueFields[field.systemName]) {
                for (const value of this.issueFields[field.systemName]) {
                  const isFieldValueAlreadyPresent = filteredOptionListItems.find(optionList =>
                    optionList.name === value
                  )
                  if (!isFieldValueAlreadyPresent) {
                    if (isCascaded) {
                      fieldValues.push({ name: value, children: [] })
                    } else {
                      fieldValues.push(value)
                    }
                  }
                }
              }
              selectedIssueFields.push({
                id         : issueField.id,
                systemName : field.systemName,
                values     : fieldValues,
                tableColumn: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD,
                isCascaded
              })
            }
          }
        }
      }
      return selectedIssueFields
    },

    /**
     * maintains mapping of advanced filter to its associated data variable
     */
    advancedFilters() {
      const advancedFilters                                                        = {}
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN[0]]              = "selectedDomains"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE[0]]            = "selectedAssignees"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL[0]]             = "selectedChannels"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL[0]]               = "selectedLabels"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS[0]]       = "selectedReportStatuses"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION[0]] = "selectedIssueAnonymisationStatuses"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE[0]]              = "selectedReportSource"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE[0]]            = "selectedReportLanguage"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0]]          = "selectedDates"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION[0]]          = "selectedIssueResolutions"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0]]            = "selectedDueDates"
      return advancedFilters
    },
    optionListItemsMap() {
      const optionListItemsMap = new Object()
      for (const optionListItem of this.pOptionListItems) {
        optionListItemsMap[optionListItem.id] = { ...optionListItem, children: [] }
      }
      return optionListItemsMap
    },
    cascadedOptionListItems() {
      const cascadedOptionListItems = []

      for (const optionListItem of this.pOptionListItems) {
        const parent = this.optionListItemsMap[optionListItem.parentId]
        if (parent) {
          parent.children.push(this.optionListItemsMap[optionListItem.id])
        } else {
          cascadedOptionListItems.push(this.optionListItemsMap[optionListItem.id])
        }
      }
      return cascadedOptionListItems
    }
  },
  methods: {
    dateRangeText(selectedDates) {
      const formattedDates = []
      for (const selectedDate of selectedDates) {
        const dateFields    = selectedDate.split("-")
        const formattedDate = [dateFields[2], dateFields[1], dateFields[0]].join("-")
        formattedDates.push(formattedDate)
      }
      return formattedDates.join(" ~ ")
    },
    saveIssueField(issueField) {
      const fieldId      = this.pIssueFields.find(pIssueField => pIssueField.id === issueField.id)?.fieldId
      const fieldType    = this.fieldsMap[fieldId].type
      const valuesToEmit = []
      if (fieldType === FIELD_TYPES.BOOLEAN.value) {
        for (const value of this.issueFields[issueField.systemName]) {
          if (value === this.$t("1781")) {
            valuesToEmit.push("true")
          }
          if (value === this.$t("1782")) {
            valuesToEmit.push("false")
          }
        }
      } else {
        valuesToEmit.push(...this.issueFields[issueField.systemName])
      }
      this.$emit("filter", [issueField.systemName, issueField.tableColumn, { "issueFieldId": [issueField.id] }], valuesToEmit)
    },
    saveFormField(field) {
      const fieldType  = this.fieldsMap[field.id].type
      let valuesToEmit = []
      if (fieldType === FIELD_TYPES.BOOLEAN.value) {
        for (const value of this.formFields[field.systemName]) {
          if (value === this.$t("1781")) {
            valuesToEmit.push("true")
          }
          if (value === this.$t("1782")) {
            valuesToEmit.push("false")
          }
        }
      } else {
        valuesToEmit = this.formFields[field.systemName]
      }
      this.$emit("filter", [field.systemName, field.tableColumn, { "fieldId": [field.id] }], valuesToEmit)
    },
    removeIssueTypeChip(index) {
      this.selectedIssueTypes = this.selectedIssueTypes.filter((_, i) => i !== index)
    },
    removeIssueStatusChip(index) {
      this.selectedIssueStatuses = this.selectedIssueStatuses.filter((_, i) => i !== index)
    },
    removeIssueResolutionChip(index) {
      this.selectedIssueResolutions = this.selectedIssueResolutions.filter((_, i) => i !== index)
    },
    removeSelectedAdvancedFilter(index) {
      this.selectedAdvancedFilters = this.selectedAdvancedFilters.filter((_, i) => i !== index)
    },
    removeIssueIdChip(index) {
      this.selectedIssueIds = this.selectedIssueIds.filter((_, i) => i !== index)
    },
    isIssueTypeCase(item) {
      return item.value === ISSUE_TYPES[0].id
    },
    isIssueTypeTask(item) {
      return item.value === ISSUE_TYPES[1].id
    },
    selectedReportFormFieldValues(items, field) {
      delete this.formFields[field.systemName]
      this.$set(this.formFields, field.systemName, items)
      this.$emit("filter", [field.systemName, field.tableColumn, { "fieldId": [field.id] }], this.formFields[field.systemName])
    },
    selectedIssueFieldValues(items, field) {
      delete this.issueFields[field.systemName]
      this.$set(this.issueFields, field.systemName, items)
      this.$emit("filter", [field.systemName, field.tableColumn, { "issueFieldId": [field.id] }], this.issueFields[field.systemName])
    },
    /**
     * utility to ensure date range selected in calendar is maintained properly in associated model
     */
    handleInput() {
      if (this.selectedDates.length === 2) {
        const fromDate = new Date(this.selectedDates[0])
        const toDate   = new Date(this.selectedDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedDates = [this.selectedDates[1], this.selectedDates[0]]
        }
        this.showDateRangeMenu = false
      }
    },
    handleDueDateInput() {
      if (this.selectedDueDates.length === 2) {
        const fromDate = new Date(this.selectedDueDates[0])
        const toDate   = new Date(this.selectedDueDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedDueDates = [this.selectedDueDates[1], this.selectedDueDates[0]]
        }
        this.showDueDateRangeMenu = false
      }
    },
    /**
     * clears the selected filter search criteria
     * @param {*} selectedFilter - filter data variable that has to be cleared
     */
    clearFilter(selectedFilter) {
      this[selectedFilter] = []
    },
    /**
     * removes the searched advance filter
     * @param {*} entity - entity that has to be excluded from filter.
     * @param {*} selectedFilter - data variable of the associated filter
     */
    removeAdvancedFilter(entity, selectedFilter) {
      this.clearFilter(selectedFilter)
      this.selectedAdvancedFilters.splice(this.selectedAdvancedFilters.indexOf(entity), 1)
    },
    /**
     * clears the filtered form field values
     * @param {*} systemName - removes previously shortlisted search values of form field.
     */
    clearFormFieldFilter(systemName) {
      this.formFields[systemName] = []
      if (!this.isClearingAllFilters) {
        this.$emit("filter", [systemName, this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE, true], [])
      }
    },
    /**
     * removes the filtered form field values
     * @param {*} systemName - removes previously shortlisted search values of form field.
     */
    removeFormFieldFilter(systemName) {
      this.clearFormFieldFilter(systemName)
      this.selectedAdvancedFilters.splice(this.selectedAdvancedFilters.indexOf(`reporter-intake-forms-${systemName}`), 1)
    },
    /**
     * removes a specific form field value from filter
     * @param {*} field - specifies the form field value amongst the form fields which will be removed.
     * @param {*} index - the position of the value in the form field values array which has to be excluded from search.
     */
    removeFormFieldValue(field, index) {
      this.formFields[field.systemName].splice(index, 1)
      this.saveFormField(field)
    },

    clearIssueFieldFilter(systemName) {
      this.issueFields[systemName] = []
      if (!this.isClearingAllFilters) {
        this.$emit("filter", [systemName, this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD, true], [])
      }
    },

    removeIssueFieldFilter(systemName) {
      this.clearIssueFieldFilter(systemName)
      this.selectedAdvancedFilters.splice(this.selectedAdvancedFilters.indexOf(`issue-fields-${systemName}`), 1)
    },

    removeIssueFieldValue(issueField, index) {
      this.issueFields[issueField.systemName].splice(index, 1)
      this.saveIssueField(issueField)
    },

    /**
     * clears all advance filters
     */
    clearFilters() {
      this.isClearingAllFilters = true
      const filtersToBeCleared  = [...this.selectedAdvancedFilters]
      for (let filterName of filtersToBeCleared) {
        if (filterName.startsWith("issue-fields-")) {
          filterName = filterName.slice("issue-fields-".length)
        } else if (filterName.startsWith("reporter-intake-forms-")) {
          filterName = filterName.slice("reporter-intake-forms-".length)
        }
        if (this.formFields[filterName]) {
          this.removeFormFieldFilter(filterName)
        } else if (this.issueFields[filterName]) {
          this.removeIssueFieldFilter(filterName)
        } else {
          this.removeAdvancedFilter(filterName, this.advancedFilters[filterName])
        }
      }
      this.selectedAdvancedFilters  = []
      this.selectedIssueStatuses    = []
      this.selectedIssueResolutions = []
      this.selectedDates            = []
      this.selectedIssueTypes       = []
      this.selectedIssueIds         = []
      this.showClearFilters         = false
      this.$emit("filter")
      this.$nextTick(() => {
        this.isClearingAllFilters = false
      })
    },
    setFormInstanceFieldFiltersFromUserPreferences() {
      const report = this.pUserIssuesFiltersPreference.report
      if (!report) {
        return
      }
      const reportWithFormInstances = report.filter(report => report.formInstances)

      for (const report of reportWithFormInstances) {
        for (const formInstance of report.formInstances) {
          for (const formInstanceField of formInstance.formInstanceFields) {
            const fieldId         = formInstanceField.fieldId[0]
            const fieldSystemName = this.fieldsMap[fieldId]?.systemName
            const fieldType       = this.fieldsMap[fieldId]?.type

            if (!fieldSystemName) {
              return
            }

            const fieldFilter         = `reporter-intake-forms-${fieldSystemName}`
            const isFieldAlreadyAdded = !!this.selectedAdvancedFilters.includes(fieldFilter)

            if (!isFieldAlreadyAdded) {
              let fieldValues = []
              if (fieldType === FIELD_TYPES.BOOLEAN.value) {
                for (const value of formInstanceField.formInstanceFieldValues[0].value) {
                  if (value === "true") {
                    fieldValues.push(this.$t("1781"))
                  }
                  if (value === "false") {
                    fieldValues.push(this.$t("1782"))
                  }
                }
              } else {
                fieldValues = formInstanceField.formInstanceFieldValues[0].value
              }

              this.selectedAdvancedFilters.push(fieldFilter)
              this.formFields[fieldSystemName] = fieldValues
              this.$emit("filter", [
                fieldSystemName,
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE,
                {
                  "fieldId": [fieldId]
                }
              ], formInstanceField.formInstanceFieldValues[0].value
              )
            }
          }
        }
      }
    },
    setIssueFieldFiltersFromUserPreferences() {
      const issueFieldValues = this.pUserIssuesFiltersPreference.issueFieldValues

      if (!issueFieldValues) {
        return
      }
      for (const issueFieldValue of issueFieldValues) {
        const issueFieldId = issueFieldValue.issueFieldId[0]
        const fieldId      = this.pIssueFields.find(issueField => issueField.id === issueFieldId)?.fieldId

        if (!fieldId) {
          return
        }
        const fieldSystemName = this.fieldsMap[fieldId]?.systemName
        const fieldType       = this.fieldsMap[fieldId]?.type

        if (!fieldSystemName) {
          return
        }

        const fieldFilter         = `issue-fields-${fieldSystemName}`
        const isFieldAlreadyAdded = !!this.selectedAdvancedFilters.includes(fieldFilter)

        if (!isFieldAlreadyAdded) {
          const filterValue = []
          if (fieldType === FIELD_TYPES.BOOLEAN.value) {
            for (const value of issueFieldValue.value) {
              if (value === "true") {
                filterValue.push(this.$t("1781"))
              }
              if (value === "false") {
                filterValue.push(this.$t("1782"))
              }
            }
          } else {
            filterValue.push(...issueFieldValue.value)
          }

          this.selectedAdvancedFilters.push(fieldFilter)
          this.issueFields[fieldSystemName] = filterValue
          this.$emit("filter", [
            fieldSystemName,
            this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD,
            {
              "issueFieldId": [issueFieldId]
            }
          ], issueFieldValue.value
          )
        }
      }
    }
  },
  watch: {
    selectedAdvancedFilters: {
      immediate: true,
      handler  : function(newFilters, oldFilters) {
        let excludedFilter = oldFilters?.find(oldFilter => newFilters.indexOf(oldFilter) === -1)
        if (excludedFilter) {
          if (excludedFilter.startsWith("issue-fields-")) {
            excludedFilter = excludedFilter.slice(0, "issue-fields-".length - 1)
          } else if (excludedFilter.startsWith("reporter-intake-forms-")) {
            excludedFilter = excludedFilter.slice(0, "reporter-intake-forms-".length - 1)
          }

          if (this.formFields[excludedFilter]) {
            this.removeFormFieldFilter(excludedFilter)
          } else if (this.issueFields[excludedFilter]) {
            this.removeIssueFieldFilter(excludedFilter)
          } else {
            this.clearFilter(this.advancedFilters[excludedFilter])
          }
        }
        this.showClearFilters = !!newFilters.length
      }
    },
    selectedDomains: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN, newValue)
        }
      }
    },
    selectedAssignees: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE, newValue)
        }
      }
    },
    selectedIssueStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.STATUS, newValue)
        }
      }
    },
    selectedIssueTypes: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.TYPE, newValue)
        }
      }
    },
    selectedIssueResolutions: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION, newValue)
        }
      }
    },
    selectedDates: {
      immediate: true,
      handler  : function(newValue) {
        if ((!newValue.length || newValue.length === 2) && !this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE, newValue)
        }
      }
    },
    selectedDueDates: {
      immediate: true,
      handler  : function(newValue) {
        if ((!newValue.length || newValue.length === 2) && !this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE, newValue)
        }
      }
    },
    selectedChannels: {
      immediate: true,
      handler  : function(newValue) {
        if(!this.isClearingAllFilters){
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL], newValue)
        }
      }
    },
    selectedReportStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if(!this.isClearingAllFilters){
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS], newValue)
        }
      }
    },
    selectedIssueAnonymisationStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if(!this.isClearingAllFilters){
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION], newValue)
        }
      }
    },
    selectedLabels: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL, newValue)
        }
      }
    },

    pFields: {
      immediate: true,
      handler  : function(newValue) {
        this.fieldsMap = {}
        for (const field of newValue) {
          this.fieldsMap[field.id] = field
        }
        this.setFormInstanceFieldFiltersFromUserPreferences()
        this.setIssueFieldFiltersFromUserPreferences()
      }
    },

    pIssueFields: {
      immediate: true,
      handler  : function() {
        this.setIssueFieldFiltersFromUserPreferences()
      }
    },
    selectedIssueIds: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ID, newValue)
        }
      }
    },
    pUserIssuesFiltersPreference: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const id                 = newValue.id
          const issueStatus        = newValue.statusId
          const issueResolution    = newValue.resolutionId
          const createdAt          = newValue.createdAt
          const dueDate            = newValue.dueDate
          const domain             = newValue.domainId
          const assignee           = newValue.assigneeId
          const report             = newValue.report
          const labels             = newValue.labels
          const issueType          = newValue.typeId
          const issueAnonymisation = newValue.dataRetentionStatus
          if (report) {
            const channelFilters      = report.find(report => report.channelId)?.channelId
            const reportStatusFilters = report.find(report => report.status)?.status
            const reportSourceFilters = report.find(report => report.source)?.source
            const languageFilters     = report.find(report => report.languageId)?.languageId
            if (channelFilters) {
              const isChannelAlreadyAdded =
                !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL[0])
              if (!isChannelAlreadyAdded) {
                this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL[0])
                this.selectedChannels = channelFilters
              }
            }
            if (reportStatusFilters) {
              const isReportStatusAlreadyAdded =
                !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS[0])
              if (!isReportStatusAlreadyAdded) {
                this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS[0])
                this.selectedReportStatuses = reportStatusFilters
              }
            }
            if (reportSourceFilters) {
              const isReportSourceAlreadyAdded =
                !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE[0])
              if (!isReportSourceAlreadyAdded) {
                this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE[0])
                this.selectedReportSource = reportSourceFilters
              }
            }
            if (languageFilters) {
              const isLanguageAlreadyAdded =
                !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE[0])
              if (!isLanguageAlreadyAdded) {
                this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE[0])
                this.selectedReportLanguage = languageFilters
              }
            }
            this.setFormInstanceFieldFiltersFromUserPreferences()
          }
          this.setIssueFieldFiltersFromUserPreferences()

          if (labels) {
            const isLabelAlreadyAdded =
              !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL[0])
            if (!isLabelAlreadyAdded) {
              this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL[0])
              this.selectedLabels = labels
            }
          }
          if (id) {
            this.selectedIssueIds = id
          }

          if (issueStatus) {
            this.selectedIssueStatuses = issueStatus
          }
          if (issueResolution) {
            const isIssueResolutionAlreadyAdded =
              !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION[0])
            if (!isIssueResolutionAlreadyAdded) {
              this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION[0])
              this.selectedIssueResolutions = issueResolution
            }
          }
          if (issueType) {
            this.selectedIssueTypes = issueType
          }
          if (issueAnonymisation) {
            const isIssueAnonymisationAlreadyAdded =
              !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION[0])
            if (!isIssueAnonymisationAlreadyAdded) {
              this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION[0])
              this.selectedIssueAnonymisationStatuses = issueAnonymisation
            }
          }
          if (createdAt) {
            const isDateRangeAlreadyAdded =
              !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0])
            if (!isDateRangeAlreadyAdded) {
              const formattedDates = []
              const datesSplit     = createdAt.split("to")
              const fromDate       = this.$moment(new Date(datesSplit[0])).format("YYYY-MM-DD")
              const toDate         = this.$moment(new Date(datesSplit[1])).format("YYYY-MM-DD")
              formattedDates.push(fromDate)
              formattedDates.push(toDate)
              this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0])
              this.selectedDates = formattedDates
            }
          }
          if (dueDate) {
            const isDateRangeAlreadyAdded =
              !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0])
            if (!isDateRangeAlreadyAdded) {
              const formattedDates = []
              const datesSplit     = dueDate.split("to")
              const fromDate       = this.$moment(new Date(datesSplit[0])).format("YYYY-MM-DD")
              const toDate         = this.$moment(new Date(datesSplit[1])).format("YYYY-MM-DD")
              formattedDates.push(fromDate)
              formattedDates.push(toDate)
              this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0])
              this.selectedDueDates = formattedDates
            }
          }
          if (domain) {
            const isDomainAlreadyAdded =
              !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN[0])
            if (!isDomainAlreadyAdded) {
              this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN[0])
              this.selectedDomains = domain
            }
          }
          if (assignee) {
            const isAssigneeAlreadyAdded =
              !!this.selectedAdvancedFilters.includes(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE[0])
            if (!isAssigneeAlreadyAdded) {
              this.selectedAdvancedFilters.push(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE[0])
              this.selectedAssignees = assignee
            }
          }
        }
      }
    },
    selectedReportSource: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE], newValue)
        }
      }
    },
    selectedReportLanguage: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE], newValue)
        }
      }
    }
  }
}
